import {
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import ReactHtmlParser from 'react-html-parser';
import {
  Box,
  CardContent,
  Container,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment/moment';
import { array } from 'prop-types';
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import DefaultCard from '~components/DefaultCard';
import NoOrdersView from '~components/NoOrdersView';
import {
  GET_RESTAURANT,
  GET_TOKEN,
} from '~graphql/queries';
import { DeliveryType, localstorageKeys } from '~types';
import {
  timeStringToISO,
  timeStringToMinutes,
  toTimeFormat,
} from '~utils/formatHelper';
import {
  getLocalStorageValue,
  setLocalStorageValue,
} from '~utils/localstorageHelper';
import {
  deliveryFormFields,
  getFormValues,
  getpreparationTime,
} from '~utils/orderHelper';
import getSearchParams from '~utils/getSearchParam';
import { validatePhoneNumber } from '~utils/paymentUtils';
import ActionDialog from '../../components/ActionDialog';
import OrderDescription from '../../components/OrderDescription';
import OrderForm from '../../components/OrderForm';
import OrderTimeButton from '../../components/OrderTimeButton';
import PaymentButton from '../../components/PaymentButton';
import SEO from '../../components/seo';
import AppContext from '../../contexts/AppContext';
import {
  ADD_ITEMS_TO_ORDER,
  ADD_ORDER,
  CREATE_CUSTOMER,
  GET_PAYMENT,
} from '../../graphql/mutations';
import { substringZero } from '../../utils/numberSubstring';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.margins.marginToHeader - 40,
    marginBottom: theme.margins.bottomNavHeight + 24,
  },
  paymentButtonContainer: {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: theme.margins.bottomNavHeight,
    zIndex: theme.zIndex.appBar,
    backgroundColor: '#fff',
  },
  cardContainer: {
    width: '100%',
    height: '60vh',
    margin: 'auto',
  },
  cardContainer2: {
    display: 'none',
  },
  listItem: {
    width: '100%',
    height: '100%',
    paddingLeft: 0,
    paddingRight: 0,
  },
  deliveryContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  card: {
    minHeight: 0,
  },
  modalClose: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '28px',
  },
  termsText: {
    fontSize: '13px',
    marginTop: '20px'
  }

}));

const OrderPage = () => {
  const [focused, setFocused] = useState(false);

  const isPaymentCanceled = getSearchParams('payment-cancelled');

  useEffect(()=>{
    if(isPaymentCanceled){
      setErrorMsg(
        "Payment cancelled! Please try again."
      );
      setShowErrorDialog(true);
    }
  },[isPaymentCanceled])

  const onFocus = () => setFocused(true);

  const onBlur = () => setFocused(false);

  const styles = useStyles();
  const { t } = useTranslation();

  const [disableSubmitBtn, setDisableSubmitBtn] = useState(
    false
  );
  const [phoneNumber, setPhoneNumber] = useState('');
  const formRef = useRef();

  const {
    state: {
      items: { localItems },
      deliveryType,
    },
    actions: { deleteItem, editItem },
    restaurantId,
    customerId,
    formatPrice,
    isRestaurantOpen,
    tableId: tableIdContext,
  } = useContext(AppContext);

  const { data: restaurantData } = useQuery(
    GET_RESTAURANT,
    {
      variables: {
        restaurantId,
      },
      onCompleted: restaurantData => {
        if (!restaurantData) return;
        const { deliveryCost } = restaurantData.restaurant;
        if (
          deliveryType === DeliveryType.DELIVERY &&
          deliveryCost
        ) {
          setDeliveryPrice(deliveryCost.cost);
        }

        setInitialPreparationTime();

        const prepTime = getpreparationTime(
          restaurantData.restaurant,
          deliveryType
        );

        setExtraData(prevState => ({
          ...prevState,
          orderTime: toTimeFormat(prepTime),
        }));
      },
    }
  );

  const [addOrder] = useMutation(ADD_ORDER, {
    onCompleted: data => setOrderId(data.postOrders.id),
    onError: error => {
      console.log(error.graphQLErrors[0].extensions.responseBody.error
        .message)
      setErrorMsg(
        "Some error occured! Please try again later."
      );
      setShowErrorDialog(true);
      setShowLoader(false);
      setDisableSubmitBtn(false);
    },
  });

  const [getToken, { data: tokenData }] = useLazyQuery(
    GET_TOKEN
  );

  const [createCustomer] = useMutation(CREATE_CUSTOMER, {
    onCompleted: data => {
      setLocalStorageValue(localstorageKeys.webId, {
        id: data.postCustomers.id,
        [localstorageKeys.timeStamp]: Date.now(),
      });
      getToken({
        variables: { id: data.postCustomers.id },
      });
    },
    onError: error => {
      console.log(error.graphQLErrors[0].extensions.responseBody.error
        .message)
      setErrorMsg(
        "Some error occured! Please try again later."
      );
      setShowErrorDialog(true);
      setShowLoader(false);
      setDisableSubmitBtn(false);
    },
  });

  useEffect(() => {
    const token = tokenData?.getToken?.token;
    if (token) {
      const { id } = getLocalStorageValue(
        localstorageKeys.webId
      );
      setLocalStorageValue(localstorageKeys.token, {
        token,
        [localstorageKeys.timeStamp]: Date.now(),
      });
      handleAddOrder(id);
    }
  }, [tokenData]);

  const [
    addItemsToOrder,
    { loading: addItemsLoading },
  ] = useMutation(ADD_ITEMS_TO_ORDER, {
    onCompleted: data => onAddItemsToOrderCompleted(),
    onError: error => {
      console.log(error.graphQLErrors[0].extensions.responseBody.error
        .message)
      setErrorMsg(
        "Some error occured! Please try again later."
      );
      setShowErrorDialog(true);
      setShowLoader(false);
      setDisableSubmitBtn(false);
    }
  });

  const [paymentOperations, setPaymentOperations] = useState({})

  const [getPayment] = useMutation(GET_PAYMENT, {
    onCompleted: data => setPaymentOperations(data),
    onError: error => {
      console.log(error.graphQLErrors[0].extensions.responseBody.error
        .message)
      setErrorMsg(
        "Some error occured! Please try again later."
      );
      setShowErrorDialog(true);
      setShowLoader(false);
      setDisableSubmitBtn(false);
    }
  });

  useEffect(() => {
    if (paymentOperations?.patchOrdersGetPayment?.length) {
      const redirectObject = paymentOperations?.patchOrdersGetPayment?.find(item => item?.rel === 'redirect-checkout')
      if (redirectObject?.href) {
        const paymentLink = redirectObject?.href
        window.location.href = paymentLink;
      } else {
        setErrorMsg(
          "Some error occured! Please try again later."
        );
        setShowErrorDialog(true);
        setShowLoader(false);
        setDisableSubmitBtn(false);
      }
    }
  }, [paymentOperations]);

  const [deliveryPrice, setDeliveryPrice] = useState(null);

  const [showDeleteDialog, setShowDeleteDialog] = useState(
    false
  );

  const [tableId, setTableId] = useState(tableIdContext);
  const [orderId, setOrderId] = useState(null);

  const [extraData, setExtraData] = useState({
    [deliveryFormFields.address]: '',
    [deliveryFormFields.phoneNumber]: '',
    [deliveryFormFields.zipCode]: '',
    orderTime: '',
  });

  const [showLoader, setShowLoader] = useState(false)
  const [itemId, setItemId] = useState('');
  const [errorMsg, setErrorMsg] = useState(null);
  const [showErrorDialog, setShowErrorDialog] = useState(
    false
  );
  const [preparationTime, setPreparationTime] = useState(0);

  useEffect(() => {
    if (orderId) {
      onAddOrderCompleted(orderId);
    }
  }, [orderId]);

  const setInitialPreparationTime = () => {
    const prepTime = getpreparationTime(
      restaurantData.restaurant,
      deliveryType
    );
    setPreparationTime(prepTime);
  };

  const handleQuantityChange = (e, quantity, product) => {
    e.preventDefault();
    if (quantity < 1) {
      setItemId(product.localId);
      toggleDeleteDialog();
    } else {
      const updatedProduct = {
        ...product,
        quantity,
      };
      editItem(updatedProduct);
    }
  };

  const handleTableIdChange = e => {
    setTableId(+e.target.value);
  };

  const handlePrepTimeChange = timeString => {
    setPreparationTime(timeStringToMinutes(timeString));
  };

  const toggleDeleteDialog = () =>
    setShowDeleteDialog(prevState => !prevState);

  const toggleErrorDialog = () =>
    setShowErrorDialog(prevState => !prevState);

  const handleAddOrder = (id, deliveryFormValues) => {
    const orderTime = timeStringToISO(
      toTimeFormat(preparationTime) || extraData?.orderTime
    );

    const deliveryInfo = {
      ...(deliveryFormValues ?? extraData),
      orderTime,
    };

    addOrder({
      variables: {
        restaurantId,
        customerId: id ?? customerId,
        tableId:
          tableId &&
            deliveryType === DeliveryType.RESERVATION
            ? tableId
            : undefined,
        deliveryType,
        ...deliveryInfo,
      },
    });
  };

  const validatePhone = (unformattedNumber) => {
    
    const {
      errMsg,
      isValid,
      phoneNumber,
    } = validatePhoneNumber(
      substringZero(unformattedNumber)
    );
    if (isValid) {
      setPhoneNumber(phoneNumber);
      formRef.current.setFieldValue('phoneNumber', phoneNumber)
    }
  }
  const [isFormValid, setIsFormValid] = useState(false);

  const onFormChange = () => {
    setTimeout(() => {
      const isFormValid = formRef.current.isValid;
      setIsFormValid(isFormValid)
    }, 0);
  }

  const onSubmitOrder = () => {

    if (formRef.current) {
      formRef.current.handleSubmit()
    }

    if (!isFormValid) {
      return false;
    }
    setDisableSubmitBtn(true);
    setShowLoader(true);

    if (orderId) {
      onAddOrderCompleted(orderId);
      return;
    }

    const formValues = getFormValues(formRef?.current);
    if (
      !formValues &&
      deliveryType !== DeliveryType.RESERVATION
    ) {
      return;
    }

    if (!customerId) {
      setExtraData(formValues?.deliveryFields);
      createCustomer({
        variables: formValues?.createCustomerFields,
      });
      return;
    }

    handleAddOrder(null, formValues?.deliveryFields);
  };

  const onAddOrderCompleted = orderId => {
    const items = localItems.map(item => {
      const {
        quantity,
        itemId,
        specialRequest,
        variants,
        allergies,
        productGroupId,
      } = item;
      const getVariantIds = variant =>
        Array.isArray(variant)
          ? variant
            .map(option => option.checked && option.id)
            .filter(isNumber => isNumber)
          : [Number(variant)];

      const allergyIds = getVariantIds(allergies);
      const variantOptionsIds = Object.values(
        variants
      ).reduce(
        (result, variant) => [
          ...result,
          ...getVariantIds(variant.options),
        ],
        []
      );
      return {
        quantity,
        itemId,
        specialRequest,
        variantOptionsIds,
        allergyIds,
        productGroupId,
      };
    });
    addItemsToOrder({ variables: { orderId, items } });
  };

  const onAddItemsToOrderCompleted = () => {

    getPayment({
      variables: {
        orderId,
        phoneNumber
      }
    });

  };

  const orderTotal = () => {
    let total = localItems.reduce(
      (total, item) =>
        total + item.totalPrice * item.quantity,
      0
    );
    if (deliveryPrice) {
      total += deliveryPrice;
    }
    return formatPrice(Math.round(total));
  };

  const termsText = (text) => {
    if (text.includes('terms')) {
      return text.replace('terms', `<a target="_blank" href="${restaurantData?.restaurant?.termsUrl?.url}">terms</a>`)
    } else if (text.includes('villkoren')) {
      return text.replace('villkoren', `<a target="_blank" href="${restaurantData?.restaurant?.termsUrl?.url}">villkoren</a>`)
    }
  }

  return (
    <Container className={styles.container}>
      <SEO title={t('Order')} />
      {localItems && localItems.length > 0 ? (
        <>
          <Box marginY={2}>
            <OrderDescription
              order={localItems}
              formatPrice={formatPrice}
              handleQuantityChange={handleQuantityChange}
              tableId={
                deliveryType === 'RESERVATION'
                  ? tableId
                  : null
              }
            />
          </Box>
          {deliveryType === DeliveryType.DELIVERY && (
            <Box className={styles.listItem}>
              <DefaultCard className={styles.card}>
                <CardContent>
                  <Box className={styles.deliveryContent}>
                    <Typography>
                      <b> {t('DeliveryCost')}</b>
                    </Typography>
                    <Typography
                      color="primary"
                      align="right"
                      variant="body1"
                    >
                      <b>{formatPrice(deliveryPrice)}</b>
                    </Typography>
                  </Box>
                </CardContent>
              </DefaultCard>
            </Box>
          )}

          {deliveryType !== 'RESERVATION' && (
            <OrderTimeButton
              preparationTime={preparationTime}
              deliveryType={deliveryType}
              onPrepTimeChange={handlePrepTimeChange}
            />
          )}
          <OrderForm
            customerId={!!customerId}
            deliveryType={deliveryType}
            formRef={formRef}
            onFocus={onFocus}
            onBlur={onBlur}
            validatePhone={validatePhone}
            onFormChange={onFormChange}
          />
          <Typography className={styles.termsText}>
            {ReactHtmlParser(termsText(t('terms')))}
          </Typography>
          <Box marginY={3}>
            <Box className={styles.paymentButtonContainer}>
              <PaymentButton
                onClick={
                  disableSubmitBtn
                    ? () => null
                    : onSubmitOrder
                }
                orderTotal={orderTotal()}
                title={t('Pay')}
                loading={addItemsLoading || showLoader}
                isRestaurantOpen={isRestaurantOpen}
                isFormValid={isFormValid}
              />
            </Box>
          </Box>
          <ActionDialog
            open={showErrorDialog}
            title={errorMsg}
            secondActionText={t('ok')}
            handleSecondAction={() =>
              toggleErrorDialog(false)
            }
            onBackdropClick={() => toggleErrorDialog(false)}
            fullWidth={false}
          />
          <ActionDialog
            open={showDeleteDialog}
            title={t('RemoveProductDesc')}
            firstActionText={t('No')}
            secondActionText={t('Yes')}
            handleFirstAction={toggleDeleteDialog}
            handleSecondAction={() => {
              deleteItem(itemId);
              toggleDeleteDialog();
            }}
            onBackdropClick={toggleDeleteDialog}
            fullWidth={false}
          />
        </>
      ) : (
        <NoOrdersView />
      )}
    </Container>
  );
};

OrderPage.propTypes = {
  order: array,
};

OrderPage.defaultProps = {
  order: [],
};

export default OrderPage;
