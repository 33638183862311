import {
  Box,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';

import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createCustomerFormFields,
  deliveryFormFields,
} from '~utils/orderHelper';
import { getOrderFromInitialValues } from '~utils/orderHelper.ts';
import { DeliveryType } from '../types';
import {
  createCustomerDeliverySchema,
  createCustomerSchema,
  deliverySchema,
  phoneNumberSchema,
  createReservationSchema
} from '../utils/validationSchemas';
import DefaultCard from './DefaultCard';
import FormTextField from './FormTextField';

const useStyles = makeStyles(theme => ({
  card: {
    padding: theme.spacing(2),
    borderRadius: theme.borderRadius.main,
  },
}));

export default ({ customerId, deliveryType, formRef, onBlur, onFocus, validatePhone, onFormChange }) => {
  
  const styles = useStyles();
  const { t } = useTranslation();

  const getSchema = () => {
 
    if (!customerId && deliveryType === 'DELIVERY') {
      return createCustomerDeliverySchema;
    }
    if (deliveryType === 'DELIVERY') {
      return deliverySchema;
    }
    if (!customerId && deliveryType === 'RESERVATION') {
      return createReservationSchema;
    }
    if (!customerId) return createCustomerSchema;

    return phoneNumberSchema;
  };


  const {
    initialValues,
    validationSchema,
  } = useMemo(() => {
    const initialValues = getOrderFromInitialValues(
      customerId,
      deliveryType
    );
    const validationSchema = getSchema();
    return { initialValues, validationSchema };
  }, [customerId, deliveryType]);

  if (
    customerId &&
    deliveryType === DeliveryType.RESERVATION
  ) {
    return null;
  }

  const handleBlurPhone = (errors)=>{
    if(!errors){
      onBlur();
    }
  }


  return (
    <Grid container>
      <DefaultCard className={styles.card}>
        <Box mb={2}>
          <Typography>{t('orderFormTitle')}</Typography>
        </Box>
        <Formik
          innerRef={formRef}
          validateOnMount
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values) => console.log()}
        >
          {formik => (
            <Form onChange={onFormChange}>
              
              <Grid container spacing={2}>
                {!customerId && (
                  <>
                    <Grid item xs={12}>
                      <FormTextField
                        placeholder={'Sven'}
                        label={t('firstName')}
                        name={
                          createCustomerFormFields.firstName
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormTextField
                        placeholder={'Svensson'}
                        label={t('lastName')}
                        name={
                          createCustomerFormFields.lastName
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormTextField
                        placeholder={
                          'sven.svensson@example.com'
                        }
                        label={t('email')}
                        name={
                          createCustomerFormFields.email
                        }
                      />
                    </Grid>
                  </>
                )}
                {deliveryType === DeliveryType.DELIVERY && (
                  <>
                    <Grid item xs={12}>
                      <FormTextField
                        placeholder={t(
                          'placeholderAddress'
                        )}
                        label={t('Address')}
                        name={deliveryFormFields.address}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormTextField
                        placeholder={t(
                          'placeholderPostCode'
                        )}
                        label={t('Postcode')}
                        name={deliveryFormFields.zipCode}
                      />
                    </Grid>
                  </>
                )}
                {deliveryType !==
                  DeliveryType.RESERVATION && (
                    <Grid item xs={12}>
                      <FormTextField
                        fullWidth
                        onBlur={(e)=>{
                          formik.handleBlur(e);
                          handleBlurPhone(formik.errors?.phoneNumber);
                        }}
                        placeholder={t(
                          'placeholderPhoneNumber'
                        )}
                        onChange={(e) => {
                          formik.validateField('phoneNumber');
                          formik.handleChange(e);
                          validatePhone(e.target.value)
                        }}
                        label={t('PhoneNumber')}
                        name={deliveryFormFields.phoneNumber}
                      />
                    </Grid>
                  )}
              </Grid>
            </Form>
          )}
        </Formik>
      </DefaultCard>
    </Grid>
  );
};
